// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';

import './src/styles/main.scss';

// const loadableReady = require("@loadable/component").loadableReady;

// exports.replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     loadableReady(() => {
//       ReactDOM.render(element, container, callback)
//     })
//   }
// }

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 0);
  }
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  window.history.scrollRestoration = 'manual';
  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: 'smooth' });
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        // top: currentPosition[1],
        // left: currentPosition[0],
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, 0);

  // do not let gatsby do anything more
  return false;
};
