module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Orbitvu","short_name":"Orbitvu","start_url":"/","lang":"en","background_color":"#ffffff","theme_color":"#24a1b4","display":"standalone","icon":"src/assets/favicons/favicon-96x96.png","icons":[{"src":"favicons/android-icon-192x192.webp","sizes":"192x192","type":"image/webp"},{"src":"favicons/apple-icon-57x57.webp","sizes":"57x57","type":"image/webp"},{"src":"favicons/apple-icon-60x60.webp","sizes":"60x60","type":"image/webp"},{"src":"favicons/apple-icon-72x72.webp","sizes":"72x72","type":"image/webp"},{"src":"favicons/apple-icon-76x76.webp","sizes":"76x76","type":"image/webp"},{"src":"favicons/apple-icon-114x114.webp","sizes":"114x114","type":"image/webp"},{"src":"favicons/apple-icon-120x120.webp","sizes":"120x120","type":"image/webp"},{"src":"favicons/apple-icon-144x144.webp","sizes":"144x144","type":"image/webp"},{"src":"favicons/apple-icon-152x152.webp","sizes":"152x152","type":"image/webp"},{"src":"favicons/apple-icon-180x180.webp","sizes":"180x180","type":"image/webp"},{"src":"favicons/favicon-icon-16x16.webp","sizes":"16x16","type":"image/webp"},{"src":"favicons/favicon-icon-32x32.webp","sizes":"32x32","type":"image/webp"},{"src":"favicons/favicon-icon-96x96.webp","sizes":"96x96","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea857aa1f94cba51b9f32b909609df3e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true,"preloadTags":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
